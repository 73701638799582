import React from 'react';
import { Button, Image } from 'react-bootstrap';
import DiscordLogo from './discord.png';
import { SOCIAL_TYPE } from 'src/resources/constants/app';

const getQueryParams = (hash = null) => (hash?.replace('#', '')).split('&').map(e => e.split('=').map(decodeURIComponent)).reduce((r, [k, v]) => (r[k] = v, r), {});

const ForumDiscordLogin = ({ onSuccess, label, className, render }) => {
  const handleMessage = (event) => {
    if (event.data && event.data?.hash) {
      const dataToken = getQueryParams(event.data?.hash);
      onSuccess(SOCIAL_TYPE.DISCORD, dataToken?.access_token);
    }
  }
  const onOpenLink = () => {
    const redirectURL = encodeURIComponent(`${APP_ENV.API_ROOT_URL}/discord-oauth-callback`);
    const url = `https://discord.com/api/oauth2/authorize?response_type=token&client_id=${APP_ENV.DISCORD_CLIENT_ID}&scope=identify%20email&redirect_uri=${redirectURL}`;
    window.open(url, '_blank', 'width=515,height=680,top=260,left=702,location=1,resizable=1,statusbar=1,toolbar=0')
    window.addEventListener('message', handleMessage, false)
  }

  if (render) {
    return render({ onClick: onOpenLink });
  }

  return (
    <Button onClick={onOpenLink} className={className}>
      <Image src={DiscordLogo} className='discordLogin-logo' />
      <span className='discordLogin-label'>{label}</span>
    </Button>
  );
}

ForumDiscordLogin.defaultProps = {
  label: 'Discord'
};

export default ForumDiscordLogin;
