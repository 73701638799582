import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import loadable from '@loadable/component';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';

import {
  APP,
  COOKIE_REFERRAL,
  COOKIE_SRC,
  ERROR_CODE_INVALID_COUPON,
  ERROR_CODE_OTP,
  ERROR_CODE_USED_COUPON,
  COOKIE_CLICK_ID,
  FB_CLICK_ID
} from 'src/resources/constants/app';

import { getRecaptcha } from 'src/hocs/hocRecaptcha';
import localStore from 'src/services/localStore';
import ga, {events} from 'src/services/ga';
import {retryInputOTP, setRequireOTP, userLogout} from 'src/screens/auth/redux/action';
import {autoSocialAction} from 'src/screens/login/action';
import {authorized2FA} from 'src/screens/me/pages/Security/TwoFactor/redux/action';
import {showAlert} from 'src/screens/app/redux/action';
import reqErrorAlert from 'src/utils/errorHandler/reqErrorAlert';
import { logUserActivity, USER_ACTIONS } from 'src/components/logUserActivity';
import {LabelLang} from 'src/lang/components';
import { getSavedReferralCode } from 'src/screens/referral/utils';
import DiscordLogin from 'src/screens/login/components/discordLogin';
import GoogleLogin from '../googleLogin/v2';

const LoadableFacebookLogin = loadable(() => import('../facebookLogin/v2'), { ssr: false });

const SocialGroupStyled = styled.div`
  display: flex;
  align-items: center;
  button {
    margin: 2px;
  }
  > span, button {
    &, &:hover, &:active, &:focus, &:disabled, &.disabled {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F3F6F8;
      color: #171717;
      border-radius: 16px;
      height: 46px;
      font-size: 13px;
      box-shadow: none;
      opacity: 1 !important;
      ${props => props.theme === 'dark' && css`
        color: white;
        border: 1px solid transparent;
        background:
          linear-gradient(#272A3A, #272A3A) padding-box /* Don't extend this to border */,
          linear-gradient(to left, #FF75AE, #54D5FF) border-box; /*Border-box is not need as it's the default value*/
      `}
    }
  }
  img {
    height: 18px;
    width: auto;
    margin-right: 0.5rem;
  }
  
  @media screen and (max-width: 768px) {
    //>span, button {
    //  height: 3.5rem;
    //}
  }
`;

const SocialGroup = (props) => {
  const {
    promotionCode, referralCode,
    onSuccess, onPreCheckSubmit, useReferralCode,
    autoSocialAction, showAlert,
    setRequireOTP, retryInputOTP, onRequireOTP,
    logUserActivity, theme
  } = props;
  const [socialToken, setSocialToken] = useState(null);
  const [socialType, setSocialType] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const referral = useReferralCode && referralCode || getSavedReferralCode() || '';
  const clickId = Cookies.get(COOKIE_CLICK_ID) || '';

  const doLogin = async (otp) => {
    const Source = localStore.get(APP.SOURCE) || window.location.href;
    const captcha = await getRecaptcha('loginwithfacebook');
    const sessionId = localStore.get('session_id') || null;
    const fbc = Cookies.get(FB_CLICK_ID) || null;

    const data = {
      SocialType: socialType,
      SocialAccessToken: socialToken,
      Source,
      ReferralCode: referral,
      CouponCode: promotionCode,
      SessionID: sessionId,
      ClickID: clickId,
      FbcID: fbc
    };

    const params = queryString.parse(props.location.search);

    if(params?.sub1) {
      data.Sub1 = params?.sub1;
    }

    if(params?.sub2) {
      data.Sub2 = params?.sub2;
    }

    autoSocialAction(data, otp, captcha)
      .then((res) => {
        Cookies.remove(COOKIE_REFERRAL);
        Cookies.remove(COOKIE_SRC);
        Cookies.remove(COOKIE_CLICK_ID);
        setRequireOTP(false, null);
        retryInputOTP(false);
        if (res.isNewUser) {
          callEventGa(res);
        } else {
          logActionNotNewUser(res);
        }
        if (typeof onSuccess === 'function') {
          onSuccess(res);
        }
      })
      .catch(err => {
        if(err?.code === ERROR_CODE_OTP) {
          setRequireOTP(true, doLogin);
          retryInputOTP(true);
          onRequireOTP && onRequireOTP(true);
        } else if(err?.code === ERROR_CODE_INVALID_COUPON) {
          showAlert({
            message: 'error.serverCode.invalidCoupon',
            type: 'danger'
          });
        } else if(err?.code === ERROR_CODE_USED_COUPON) {
          showAlert({
            message: 'error.serverCode.usedCoupon',
            type: 'danger'
          });
        } else if (err?.code === -9004) {
          showAlert({
            message: 'error.serverCode.notVerifiedEmailDiscord',
            type: 'danger'
          });
        } else {
          reqErrorAlert(err, { message: <LabelLang id='user.login.loginFailed' /> });
        }
      })
      .finally(() => {
        setLoggingIn(false);
      });
  };

  const callEventGa = res => {
    if(res.method === 'facebook') {
      ga(events.category.signUp, events.action.signUpSocialFacebook, res.userId);
      logUserActivity(USER_ACTIONS.USER_SIGN_UP.signUpSocialFacebook, JSON.stringify(res));
    } else if (res.method === 'google') {
      ga(events.category.signUp, events.action.signUpSocialGoogle, res.userId);
      logUserActivity(USER_ACTIONS.USER_SIGN_UP.signUpSocialGoogle, JSON.stringify(res));
    } else {
      ga(events.category.signUp, events.action.signUp, res.userId);
      logUserActivity(USER_ACTIONS.USER_SIGN_UP.signUp, JSON.stringify(res));
    }
  };

  const logActionNotNewUser = res => {
    if(res.method === 'facebook') {
      logUserActivity(USER_ACTIONS.USER_SIGN_UP.signUpSocialFacebookNotNew, JSON.stringify(res));
    } else if (res.method === 'google') {
      logUserActivity(USER_ACTIONS.USER_SIGN_UP.signUpSocialGoogleNotNew, JSON.stringify(res));
    } else {
      logUserActivity(USER_ACTIONS.USER_SIGN_UP.signUpNotNew, JSON.stringify(res));
    }
  };

  const handleLoginFacebookSuccess = (type, token, response) => {
    setSocialType(type);
    setSocialToken(token);
  };

  const handleLoginGoogleSuccess = (type, token, response) => {
    setSocialType(type);
    setSocialToken(token);
  };

  const handleLoginDiscordSuccess = (type, token) => {
    setSocialType(type);
    setSocialToken(token);
  };

  useEffect(() => {
    if(socialType !== null && socialToken !== null) {
      setLoggingIn(true);
      doLogin(null);
    }
  }, [socialType, socialToken]);

  return (
    <SocialGroupStyled theme={theme} className={cx({'have_login_discord': props.authDiscord })}>
      {__CLIENT__ && props.authDiscord &&
        <DiscordLogin
          onSuccess={handleLoginDiscordSuccess}
        />
      }
      <GoogleLogin
        name='google'
        authDiscord={props.authDiscord}
        onPreCheckSubmit={onPreCheckSubmit}
        onLoginSuccess={handleLoginGoogleSuccess}
      />
      <LoadableFacebookLogin
        name='facebook'
        authDiscord={props.authDiscord}
        onPreCheckSubmit={onPreCheckSubmit}
        onLoginSuccess={handleLoginFacebookSuccess}
      />
    </SocialGroupStyled>
  );
};

const mapState = state => ({
});

const mapDispatch = {
  autoSocialAction,
  userLogout,
  authorized2FA,
  showAlert,
  setRequireOTP,
  retryInputOTP,
  logUserActivity
};

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
)(SocialGroup);