import React from 'react';
import LabelLang from 'src/lang/components/LabelLang';
import {Link} from 'react-router-dom';
import {URL} from 'src/resources/constants/url';
import cx from 'classnames';
import styled from 'styled-components';

const SupportStyled = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  
  a:active {
    font-size: inherit;
    font-weight: inherit;
  }
  
  .link {
    color: #0052CC;
    font-weight: 500;
  }

  .forgot {
    text-align: right;
    font-weight: 400;
  }
  
  .signUp {
    display: inline;
    color: #8F92A1;
  }
  
  .signUpAction {
    display: inline;
  }
  
  div[name='forget-password-link'] {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    text-align: right;
    font-size: 13px;
  }
  
  &.desktop {
    display: block;
  }
  
  &.mobile {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    &.desktop {
      display: none;
    }  
    
    &.mobile {
      display: block;
      text-align: center;
    }
  }
`;

export const ForgotPasswordLinkComponent = ({ isPopup, onForgetPassword }) => {
  const contentForgetPassword = <LabelLang id="user.login.forgetPassword" />;
  return (
    <SupportStyled className={cx('')}>
      {
        isPopup ? (
          <div name='forget-password-link'>
            <div role='link' onClick={onForgetPassword} className='link forgot'>
              {contentForgetPassword}
            </div>
          </div>
        ) : (
          <div name='forget-password-link'>
            <Link to={URL.USER_FORGET_PASSWORD} className='link forgot'>
              {contentForgetPassword}
            </Link>
          </div>
        )
      }
    </SupportStyled>
  );
};

export const SignUpLinkComponent = ({ isPopup, onSignup, className }) => {
  const contentSingup = <><div className='signUp'><LabelLang id="user.login.registerText" /></div> <div className="link signUpAction"><LabelLang id="user.login.registerButton" /></div></>;
  return (
    <SupportStyled className={cx(className)}>
      {
        isPopup ? (
          <div className="keepSignIn">
            <div role='link' onClick={onSignup}>
              {contentSingup}
            </div>
          </div>
        ) : (
          <div className="keepSignIn">
            <Link to={URL.USER_SIGN_UP}>
              {contentSingup}
            </Link>
          </div>
        )
      }
    </SupportStyled>
  );
};

const SupportLinkComponent = ({ isPopup, onSignup, onForgetPassword }) => {
  const contentSingup = <><div className='bold signUp'><LabelLang id="user.login.registerText" /></div> <LabelLang id="user.login.registerButton" /></>;
  const contentForgetPassword = <LabelLang id="user.login.forgetPassword" />;
  return (
    <SupportStyled className={cx('text-center')}>
      {
        isPopup ? (
          <>
            <div className="keepSignIn">
              <div role='link' name="sign-up-link" onClick={onSignup} className='link'>
                {contentSingup}
              </div>
            </div>
            <div role='link' name="forget-password-link" onClick={onForgetPassword} className='link'>
              {contentForgetPassword}
            </div>
          </>
        ) : (
          <>
            <div className="keepSignIn">
              <Link name="sign-up-link" to={URL.USER_SIGN_UP} className='link'>
                {contentSingup}
              </Link>
            </div>
            <Link name="forget-password-link" to={URL.USER_FORGET_PASSWORD} className='link'>
              {contentForgetPassword}
            </Link>
          </>
        )
      }
    </SupportStyled>
  );
};

export default SupportLinkComponent;
