import React from 'react';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import moment from 'moment-timezone/moment-timezone';
import { Button } from 'react-bootstrap';

import GooglePlayDownload from 'src/assets/images/footer/google-play-download.svg';
import AppStoreDownload from 'src/assets/images/footer/app-store-download.svg';
import Loading from 'src/components/loading';
import { requestQrCodeLogin, loginQr } from '../../action';
import { ContainerStyled, CountdownStyled, DownloadAppStyled } from './styled';
import LocalStore from 'src/services/localStore';
import ga, { events } from 'src/services/ga';
import { USER } from 'src/resources/constants/user';
import { getDownloadLink, trackIpInfo } from 'src/services/ga/ga-utils';

class QrCodeLogin extends React.PureComponent {
  interval = null;
  countdownInterval = null;

  constructor(props) {
    super(props);
    this.state = {
      qrValue: '',
      isLoading: true,
      remaining: null,
    };
  }

  componentDidMount() {
    this.handleRequestQRCode();
  }

  componentWillUnmount() {
    this.clearPolling();
  }

  handleRequestQRCode = () => {
    const { requestQrCodeLogin } = this.props; 
    requestQrCodeLogin().then(res => {
      const data = {
        Expired: res.expired,
        LoginCode: res.loginCode,
        UserAgent: __CLIENT__ && window.navigator.userAgent,
        LoginAt: new Date(),
      };
      this.setState({ qrValue: JSON.stringify(data), isLoading: false });
      this.interval = setInterval(() => this.checkLoginQr(res), 5000);
      this.countdownInterval = setInterval(() => this.updateRemaining(res, 1000));
    });
  }

  updateRemaining = (res) => {
    if (new Date > new Date(res.expired)) {
      this.clearPolling();
      return;
    }
    const end = moment(res.expired);
    let diff = end.diff(moment());
    this.setState({ remaining: moment.utc(diff).format('HH:mm:ss') });
  }

  clearPolling = () => {
    clearInterval(this.interval);
    clearInterval(this.countdownInterval);
    this.inverval = null;
    this.countdownInterval = null;
  };
  
  checkLoginQr = res => {
    const { onLoginSuccess, loginQr } = this.props;
    if (new Date > new Date(res.expired)) {
      this.clearPolling();
      return;
    }
    loginQr(res.loginCode).then(res => {
      this.clearPolling();
      onLoginSuccess(res);
    }).catch(err => {});
  };

  render() {
    const { trackIpInfo } = this.props;
    const { qrValue, isLoading, remaining } = this.state;
    const email = JSON.parse(LocalStore.get(USER.CURRENT_PROFILE))?.email || '';
    return (
      <ContainerStyled>
        {isLoading
          ? <Loading />
          : <>
            <CountdownStyled>
              {remaining === '00:00:00'
                ? <Button onClick={this.handleRequestQRCode}>Request QR code</Button>
                : <>
                  <p>This QR code will expire after</p>
                  <strong>{remaining}</strong>
                </>}
            </CountdownStyled>
            {remaining !== '00:00:00' && <QRCode size={200} value={qrValue} />}
            <p>Scan this QR code with your MyConstant mobile app to log in</p>
            <DownloadAppStyled>
              <a rel="nofollow" href={getDownloadLink('ios', events.action.downloadAppLoginQrCodeIos)} target='_blank' onClick={() => {
                ga(events.category.downloadApp, events.action.downloadAppLoginQrCodeIos, email);
                trackIpInfo(events.category.downloadApp, events.action.downloadAppLoginQrCodeIos);
              }}>
                <img src={AppStoreDownload} alt='app-store-download' height={44} />
              </a>
              <a rel="nofollow" href={getDownloadLink('android', events.action.downloadAppLoginQrCodeAndroid)} target='_blank' onClick={() => {
                ga(events.category.downloadApp, events.action.downloadAppLoginQrCodeAndroid, email);
                trackIpInfo(events.category.downloadApp, events.action.downloadAppLoginQrCodeAndroid);
              }}>
                <img src={GooglePlayDownload} alt='google-play-download' height={44} />
              </a>
            </DownloadAppStyled>
          </>
        }
      </ContainerStyled>
    );
  }
}

const mapDispatch = {
  requestQrCodeLogin,
  loginQr,
  trackIpInfo
};

export default connect(null, mapDispatch)(QrCodeLogin);