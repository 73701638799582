import { makeRequest, SERVICES } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import { USER } from 'src/resources/constants/user';
import UserModel from 'src/models/user';
import { getProfileAction, setToken } from 'src/screens/auth/redux/action';
import authentication from 'src/utils/authentication';
import LocalStore from 'src/services/localStore';
import { camelizeKeys } from 'src/utils/object';
import BrowserDetect from 'src/services/browser-detect';
import { APP } from 'src/resources/constants/app';
import { SHOW_TOP_BANNER } from 'src/components/topBanner/type';
import { AUTH_WITH_SOCIAL, LOGIN, LOGIN_MONEY_MADE, REQUEST_QR_LOGIN } from './type';

const handleLoginSuccess = (token, sessionId, dispatch) => {
  authentication.setAccessToken(token);
  LocalStore.save(APP.SESSION_ID, sessionId);
  dispatch({ type: SHOW_TOP_BANNER });
};

export const login = (data, otp = null, captcha = null, captchaV2) => dispatch => {
  const makeLogin = makeRequest(
    {
      type: LOGIN,
      url: API_URL.USER.USER_SIGN_IN,
      method: 'POST',
      data,
      headers: {OTP: otp, recaptcha: captcha, recaptchav2: captchaV2, platform: BrowserDetect.isMobile ? 'mobile-web' : 'web'}
    },
    dispatch
  );

  return makeLogin().then(res => {
    const userAuthData = UserModel.userAuth(res);
    handleLoginSuccess(userAuthData.token, res.SessionID, dispatch);
    return getProfileAction()(dispatch).then(profile => {
      const user = {
        name: `${profile.firstName || ''} ${profile.lastName || ''}`,
        email: profile.email
      };
      authentication.setCurrentUser(user);
      return {
        type: USER.LOGIN_SUCCESS,
        isAuthenticated: true
      };
    });
  }).catch(err => {
    console.log("err", err)
    throw err;
  });
};

export const autoSocialAction = (data, otp = null, captcha = null) => dispatch => {
  const req = makeRequest({
    type: AUTH_WITH_SOCIAL,
    url: API_URL.USER.AUTH_SOCIAL,
    method: 'POST',
    data,
    headers: {OTP: otp, recaptcha: captcha, platform: BrowserDetect.isMobile ? 'mobile-web' : 'web'}
  }, dispatch);
  return req().then(res => {
    dispatch(setToken(res?.Token));
    handleLoginSuccess(res?.Token, res.SessionID, dispatch);
    return getProfileAction()(dispatch).then(profile => {
      const user = {
        name: `${profile.firstName || ''} ${profile.lastName || ''}`,
        email: profile.email
      };
      authentication.setCurrentUser(user);
      return {
        type: USER.LOGIN_SUCCESS,
        isAuthenticated: true,
        userId: profile.id,
        isNewUser: res?.IsNewUser,
        method: res?.Method,
      };
    });
  }).catch(err => {
    throw err;
  });
};

export const requestQrCodeLogin = sessionId => dispatch => {
  const req = makeRequest(
    {
      type: REQUEST_QR_LOGIN,
      url: API_URL.USER.REQUEST_LOGIN_QR,
      method: 'POST',
      data: { SessionID: sessionId },
    },
    dispatch
  );
  return req().then(res => camelizeKeys(res));
};

export const loginQr = loginCode => dispatch => {
  const req = makeRequest(
    {
      type: REQUEST_QR_LOGIN,
      url: API_URL.USER.LOGIN_QR,
      method: 'POST',
      data: { LoginCode: loginCode },
      onError: () => {},
    },
    dispatch
  );
  return req().then(res => {
    dispatch(setToken(res.Token));
    handleLoginSuccess(res.Token, res.SessionID, dispatch);
    return getProfileAction()(dispatch).then(profile => {
      const user = {
        name: `${profile.firstName || ''} ${profile.lastName || ''}`,
        email: profile.email
      };
      authentication.setCurrentUser(user);
      return {
        type: USER.LOGIN_SUCCESS,
        isAuthenticated: true
      };
    });
  }).catch(err => {
    throw err;
  });
};

export const moneyMadeAuth = data => makeRequest({
  type: LOGIN_MONEY_MADE,
  url: API_URL.SAVING.AUTH_MONEY_MADE,
  method: 'POST',
  data,
  service: SERVICES.ROOT
});
