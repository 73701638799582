import React from 'react';
import {Button} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {GoogleLogin} from 'react-google-login';
import {SOCIAL_TYPE} from 'src/resources/constants/app';
import gg from './v2.svg';
import BrowserDetect from 'src/services/browser-detect';

class GoogleLoginComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  onSocialLogin = response => {
    if (!response.accessToken) {
      return;
    }

    const { onLoginSuccess } = this.props;
    if(onLoginSuccess) {
      onLoginSuccess(SOCIAL_TYPE.GOOGLE, response.accessToken, response);
    }
  };

  handleOnClick = (onClick) => {
    const { onPreCheckSubmit } = this.props;
    if (onPreCheckSubmit) {
      const isCheckOk = onPreCheckSubmit();

      if (!isCheckOk) {
        return;
      }
    }

    onClick();
  };

  render() {
    return (
      <GoogleLogin
        clientId={APP_ENV.GOOGLE_CLIENT_ID}
        render={renderProps => (
          <Button className="gg" disabled={renderProps.disabled} onClick={() => this.handleOnClick(renderProps.onClick)}>
            <img src={gg} alt="MyConstant social google login" />{BrowserDetect.isDesktop && !this.props.authDiscord &&  'Continue with'} Google
          </Button>
        )}
        onSuccess={this.onSocialLogin}
      />
    );
  }
}

const mapState = state => ({
});

const mapDispatch = {
};

export default withRouter(connect(mapState, mapDispatch)(GoogleLoginComponent));
