import styled from 'styled-components';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //grid-gap: 20px;
  p {
    font-size: 13px;
    text-align: center;
    margin-bottom: 20px;
  }

  button {
    font-size: 13px;
    margin-top: 20px;
    border-radius: 15px;
  }

  canvas {
    //  margin: 20px 0;
    margin-bottom: 20px;
  }
`;

export const CountdownStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }

  strong {
    font-size: 16px;
    color: #F89E0A;
  }
`;

export const DownloadAppStyled = styled.div`
  display: flex;
  margin-bottom: 20px;

  > a {
    margin-bottom: 5px;

    &:first-child {
      margin-right: 20px;
    }
  }

  img {
    height: 44px;
  }
`;
