import styled from 'styled-components';
import {CustomInput} from 'src/components/styled';

export const LoginSelectorStyled = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
`;

export const SelectModeItemStyled = styled.div`
  min-width: 50%;
  text-align: center;
  cursor: pointer;
  border: none;  
  color: ${props => props.active ? '#545663' : '#DBDDE6'};
  border-bottom: ${props => props.active ? '2px solid #545663' : 'none'};
  font-size: 12px;
  letter-spacing: 1px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 500;
`;

export const OrTextStyled = styled.div`
  height: 14px;
  text-align: center;
  border-bottom: 1px solid rgba(143, 146, 161, 0.1);
  margin: 10px 0 20px;
  > span {
    font-size: 12px;
    padding: 0 10px;
    display: inline-block;
    background: var(--background);
    color: #8F92A1;
    letter-spacing: 1px;
  }
`;

export const ReCaptchaCustomInput = styled(CustomInput)`
  > div > div > div {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledBackButtonWrap = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6F7FB;
  margin-left: 10px;
  margin-top: 20px;
  > img {
    max-width: 50%;
  }
`;
