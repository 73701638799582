import React from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import ReCAPTCHA from 'react-google-recaptcha';

import { getRecaptcha, preloadRecaptcha } from 'src/hocs/hocRecaptcha';
import createForm from 'src/components/core/form/createForm';
import { FieldLang } from 'src/lang/components';
import inputField from 'src/components/core/form/fields/input/v2';
import { isEmail, isRequired } from 'src/components/core/form/validator';
import LabelLang from 'src/lang/components/LabelLang';
import { showAlert } from 'src/screens/app/redux/action';
import { ERROR_CODE_OTP } from 'src/resources/constants/app';
import { MaterialCustomInput, SimpleFormStyled, SimplePageStyled } from 'src/components/styled';
import { retryInputOTP, setRequireOTP } from 'src/screens/auth/redux/action';
import SocialGroup from 'src/screens/login/components/socialGroup/v2';
import reqErrorAlert from 'src/utils/errorHandler/reqErrorAlert';
import icRowLeft from 'src/components/mobileHeader/assets/ic_left_arrow.svg';
import BrowserDetect from 'src/services/browser-detect';

import LocalStore from 'src/services/localStore';
import Loading from 'src/components/loading';
import { ForgotPasswordLinkComponent, SignUpLinkComponent } from '../supportLink';
import { login } from '../../action';
import QrCodeLogin from '../qrCodeLogin';
import {
  LoginSelectorStyled,
  OrTextStyled,
  ReCaptchaCustomInput,
  SelectModeItemStyled,
  StyledBackButtonWrap,
} from './styledV2';
import { showErrorPopup } from 'src/components/errorPopup';

const FORM_NAME = 'LoginFormWithQR';

const LoginForm = createForm({
  propsReduxForm: {
    form: FORM_NAME,
  }
});

const requiredUsername = isRequired('user.login.requiredUsername');
const checkEmail = isEmail('user.login.notValidUsername');
const requiredPassword = isRequired('user.login.requiredPassword');

const selectorForm = formValueSelector(FORM_NAME);

const getIntlKey = name => `user.login.${name}`;

const recaptchaRef = React.createRef();

const LOGIN_MODE = {
  EMAIL: 'EMAIL',
  QR_CODE: 'QR_CODE',
};

class LoginFormWithQR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggingIn: false,
      retry: 0,
      captchaValueV2: null,
      selectedMode: LOGIN_MODE.EMAIL,
    };
  }

  componentDidMount() {
    preloadRecaptcha();
  }

  doLogin = (otp, captcha) => {
    const { captchaValueV2 } = this.state;
    const { email, secretText, onSuccess, onFailed, additionData = null, setRequireOTP, onRequireOTP, retryInputOTP } = this.props;
    const sessionId = LocalStore.get('session_id') || null;

    if (email && secretText) {
      const data = {
        Email: email,
        Password: secretText,
        SessionID: sessionId,
        ...additionData
      };

      this.props.login(data, otp, captcha, captchaValueV2)
        .then(res => {
          setRequireOTP(false, null);
          retryInputOTP(false);
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
          this.setState({ loggingIn: false });
        })
        .catch(err => {
          const {retry} = this.state;
          if(err?.code === -1076 && retry < 1) {
            const self = this;
            return this.setState({retry: retry + 1}, ()=> {
              return getRecaptcha('login').then(token => {
                self.doLogin(otp, token);
              });
            });
          }

          if (typeof onFailed === 'function') {
            onFailed(err);
          }

          if(err?.code === ERROR_CODE_OTP) {
            setRequireOTP(true, this.doLogin);
            onRequireOTP && onRequireOTP(true);
            retryInputOTP(true);
          } else if (err?.code === -9004) {
            reqErrorAlert(err, { message: <LabelLang id={getIntlKey('loginFailure')} /> });
          } else {
            reqErrorAlert(err, { message: <LabelLang id={getIntlKey('loginFailure')} /> });
          }
          this.setState({ loggingIn: false });
        })
        .finally(() => {
          // this.setState({ loggingIn: false });
        });
    }
  };

  onChangeV2 = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    this.setState({ captchaValueV2: recaptchaValue });
  };

  handleSubmit = async (values) => {
    const {captchaValueV2} = this.state;
    if(!captchaValueV2) {
      return reqErrorAlert({}, { message: <LabelLang id={getIntlKey('loginFailureCaptchaV2')} /> });
    }
    this.setState({ loggingIn: true });
    const token = await getRecaptcha('login');
    this.doLogin(null, token);
    return false;
  };

  handleChangeTab = key => {
    this.setState({ selectedMode: key });
  }

  handleRegisterSocial = (res) => {
    const { onSuccess } = this.props;
    if(res?.isNewUser) {
      onSuccess(true);
    } else {
      onSuccess();
    }
  }

  handleQrCodeLogin = () => {
    const { onSuccess } = this.props;

    onSuccess();
  }

  render() {
    const { onSuccess, onRequireOTP, email, secretText, isPopup, modalActions, data } = this.props;
    const { loggingIn, selectedMode } = this.state;
    return (
      <SimplePageStyled>
        {BrowserDetect.isMobile && isPopup && (
          <StyledBackButtonWrap onClick={() => modalActions.close()}>
            <img src={icRowLeft} alt="myconstant.com" />
          </StyledBackButtonWrap>
        )}
        <div className="welcomeTitle bold login">
          <h1><LabelLang id={getIntlKey('popupTitle')} /></h1>
          <SignUpLinkComponent {...this.props} className="desktop" />
        </div>
        <div className="form">
          <LoginSelectorStyled>
            <SelectModeItemStyled active={selectedMode === LOGIN_MODE.EMAIL} onClick={() => this.handleChangeTab(LOGIN_MODE.EMAIL)}>Log in with email</SelectModeItemStyled>
            <SelectModeItemStyled active={selectedMode === LOGIN_MODE.QR_CODE} onClick={() => this.handleChangeTab(LOGIN_MODE.QR_CODE)}>Log in with QR code</SelectModeItemStyled>
          </LoginSelectorStyled>
          {selectedMode === LOGIN_MODE.QR_CODE
            ? <QrCodeLogin isShow={selectedMode === LOGIN_MODE.QR_CODE} onLoginSuccess={this.handleQrCodeLogin} />
            : (<>
              <SocialGroup authDiscord={data?.authDiscord} onSuccess={this.handleRegisterSocial} onRequireOTP={onRequireOTP} />
              <OrTextStyled className="or-text"><span>OR</span></OrTextStyled>
              <SimpleFormStyled>
                <LoginForm onSubmit={this.handleSubmit}>
                  <MaterialCustomInput>
                    <FieldLang
                      name="email"
                      id="lg_username"
                      component={inputField}
                      validate={[requiredUsername, checkEmail]}
                      type="email"
                      placeholder={getIntlKey('email')}
                      zIndex={1055}
                    />
                  </MaterialCustomInput>
                  <MaterialCustomInput>
                    <FieldLang
                      name="secretText"
                      id="lg_password"
                      component={inputField}
                      validate={[requiredPassword]}
                      type="password"
                      placeholder={getIntlKey('password')}
                      zIndex={1055}
                    />
                  </MaterialCustomInput>
                  <ForgotPasswordLinkComponent {...this.props} />
                  {email && secretText && (
                    <ReCaptchaCustomInput className="pb-3">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={APP_ENV.GOOGLE_CAPTCH_V2_SITE_KEY}
                        action='action_login'
                        onChange={this.onChangeV2}
                      />
                    </ReCaptchaCustomInput>
                  )}
                  <div className="d-grid">
                    <Button id="submit-btn" name="submit-btn" variant="primary" type="submit">
                      {loggingIn ? <Loading dark={false} /> : <LabelLang id={getIntlKey('loginButton')} />}
                    </Button>
                  </div>
                </LoginForm>
              </SimpleFormStyled>
            </>)
          }
          <SignUpLinkComponent {...this.props} className="mobile pt-3" />
        </div>
      </SimplePageStyled>
    );
  }
}

const mapStateToProps = state => ({
  email: selectorForm(state, 'email'),
  secretText: selectorForm(state, 'secretText'), // Avoid use "password" because of Facebook Pixel warning
  referralCode: selectorForm(state, 'referralCode'),
});

const mapDispatch = {
  login,
  showAlert,
  setRequireOTP,
  retryInputOTP,
};

export default compose(
  connect(mapStateToProps, mapDispatch),
  withRouter,
)(LoginFormWithQR);
